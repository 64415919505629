import React from 'react';
import { makeNewTemplateRequestsForSingleLocale } from 'src/lib/contentful-utils';
import { optimizeContentfulImages } from 'src/lib/image-helpers';
import { mapISOToContentfulLocale } from 'src/lib/utils';
import { TemplateSwitch } from 'src/templates';

const HomePageGB = (props) => {
  return <TemplateSwitch {...props} />;
};

export async function getStaticProps({ preview }) {
  const contentfulLocale = mapISOToContentfulLocale('en-gb');
  const response = await makeNewTemplateRequestsForSingleLocale(
    contentfulLocale,
    {
      'fields.url[all]': 'homepage',
    },
  );

  const entry = response.items[0];

  if (entry) {
    return {
      props: { ...optimizeContentfulImages(entry), preview: !!preview },
    };
  }

  return { props: { preview: !!preview } };
}

export default HomePageGB;
